@use "styles" as *;

.background {
  position: absolute;
  width: 100%;
  top: 0;
  z-index: -1;

  .backgroundSvg {
    width: 100%;
  }
  @include sm-desktop {
    top: 3%;
  }
}
