@use "styles" as *;

.container {
  padding: 40px 0;
  overflow: hidden;
  position: relative;

  &.white {
    background-color: $white;
  }
  &.pink {
    background-color: $light-pink;
  }
}

.introContainer {
  display: flex;
  justify-content: center;
}

.introText {
  @include text-xs-regular-uppercase;
  @include sm-desktop {
    @include text-s-regular-uppercase;
  }
}

.tabWrapper,
.textWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;
}

.textWrapper {
  @include sm-desktop {
    width: 65%;
  }
}

.title {
  margin: 16px 0;
  @include text-xxl-semibold;
  @include sm-desktop {
    @include text-mega-semibold;
  }
}

.titleHighlighted {
  margin: 16px 0;
  @include text-big-title;
}

.description {
  text-align: center;
  @include text-m-regular;
  margin: 0 16px;
  height: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;

  @include sm-desktop {
    @include text-s-regular;
    height: 35px;
    -webkit-line-clamp: 2;
  }
}

.link {
  margin-top: 16px;
  width: fit-content;
  padding: 8px 32px;
  @include sm-desktop {
    margin-top: 40px;
  }
}

.linkMobile {
  @include sm-desktop {
    display: none;
  }
}

.linkDesktop {
  display: none;
  @include sm-desktop {
    display: inline;
  }
}

.tabListContainer {
  padding: 40px 16px 24px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;
  overflow-x: auto;
  @include hide-scrollbar;

  @include sm-desktop {
    padding: 24px 0;
  }
}

.tab {
  padding: 9px 16px;
  flex-shrink: 0;
  @include text-xs-regular-uppercase;
  @include rounded-8;
  position: relative;

  &.clickedTab {
    outline: none;
  }

  &:first-child {
    margin-inline-start: auto;
  }
  &:last-child {
    margin-inline-end: auto;
  }
}

.tabTitle,
.tabTitleSelected {
  position: relative;
  z-index: 1;
}

.tabTitleSelected {
  color: $white;
}

.selectedTab {
  position: absolute;
  @include rounded-8;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

.panels {
  width: 100%;
}

.panel {
  position: relative;
}
