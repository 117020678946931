@use "breakpoints";
@use "colors";

@mixin animate {
  transition: all 300ms ease-out;
}

@mixin hide-scrollbar {
  &::-webkit-scrollbar {
    display: none;
    width: 0;
  }

  & {
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
}

@mixin background-shade {
  background: colors.$white url("../../../public/backgrounds/shade-login-registration-mobile.jpg") no-repeat 50% 50% /
    cover;

  @include breakpoints.sm-desktop() {
    background: colors.$white url("../../../public/backgrounds/shade-login-registration-desktop.jpg") no-repeat 50% 50% /
      cover;
  }
}

@mixin visuallyHidden {
  border: 0 !important;
  clip: rect(0 0 0 0) !important;
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  appearance: none !important;
  opacity: 0 !important;
  background: transparent !important;

  &::before,
  &::after {
    display: none;
    content: none;
  }
}

@mixin accordion-open {
  grid-template-rows: 1fr;
  visibility: visible;
}

@mixin accordion-close {
  grid-template-rows: 0fr;
  visibility: hidden;
  transition-property: grid-template-rows, visibility, padding;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
}
