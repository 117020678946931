@mixin rounded-4 {
  border-radius: 4px;
}

@mixin rounded-8 {
  border-radius: 8px;
}

@mixin rounded-12 {
  border-radius: 12px;
}

@mixin rounded-16 {
  border-radius: 16px;
}

@mixin rounded-20 {
  border-radius: 20px;
}

@mixin rounded-24 {
  border-radius: 24px;
}

@mixin rounded-50-percentage {
  border-radius: 50%;
}
